const BaseURL = "https://partnersapi.applygateway.com/api/";
const WebLinks = {
    // user
    Login: BaseURL + 'Login/BackofficeLogin',
    // buyer
    Buyers: BaseURL + 'BackofficeBuyer',
    CountryList: BaseURL + 'BackofficeBuyer/GetCountryList',
    DeleteBuyer: BaseURL + 'BackofficeBuyer/DeleteBuyer?buyerId=',
    GetBuyerDetail: BaseURL + 'BackofficeBuyer/BuyerDetails?buyerId=',
    GetBuyerEmployer: BaseURL + 'BackofficeBuyer/EmployerDetails?buyerId=',
    GetBuyersList: BaseURL + 'BackofficeBuyer/EntireBuyerList',
    GetAllowedBuyers: BaseURL + 'BackofficeBuyer/AllowedBuyers?buyerId=',
    GetParentBuyers: BaseURL + 'BackofficeBuyer/ParentBuyers?buyerId=',
    SaveSubBuyers: BaseURL + 'BackofficeBuyer/UpdateSubAccounts',
    SaveParentBuyers: BaseURL + 'BackofficeBuyer/UpdateParentAccounts',
    SubmitBuyerEmployer: BaseURL + 'BackofficeBuyer/UpdateEmployer',
    GetAllowedSuppliers: BaseURL + 'BackofficeBuyer/AllowedSuppliers?buyerId=',
    SaveAllowedSuppliers: BaseURL + 'BackofficeBuyer/SetAllowedSuppliers?buyerId=',
    UpdateBuyer: BaseURL + 'BackofficeBuyer/UpdateBuyerInfo',
    // supplier
    Suppliers: BaseURL + 'BackofficeSupplier',
    GetSupplierDetail: BaseURL + 'BackofficeSupplier/SupplierDetails/',
    GetAllowedBuyersSuppliers: BaseURL + 'BackofficeSupplier/AllowedBuyers?supplierId=',
    SaveAllowedBuyersSupplier: BaseURL + 'BackofficeSupplier/SetAllowedBuyers?supplierId=',
    //image
    Images: BaseURL + 'BackofficeImage/GetImagesList',
    AddImage: BaseURL + 'BackofficeImage/AddImage',
    //users
    GetUsers: BaseURL + 'BackOfficeUser',
    UpdateUsers: BaseURL + 'BackOfficeUser/UpdateUser',
    DeleteUsers: BaseURL + 'BackOfficeUser/DeleteUser?userId=',
    //Boost tenants
    GetBoostTenants: BaseURL + 'BackofficeBuyer/GetAllBoostTenants',
    //IndeedData
    IndeedData: BaseURL + 'BackofficeIndeed/GetIndeedData',
    IndeedDataSave: BaseURL + 'BackofficeIndeed/AddIndeedImage',
    IndeedDataSpend: BaseURL + 'BackofficeIndeed/InsertIndeedDataSpend',
    //Occupation
    Occupation:  BaseURL + 'BackofficeOccupation',
    GetOccupation:  BaseURL + 'BackofficeOccupation/Occupation/'
};
export default WebLinks;
