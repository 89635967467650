import { Form, Input, Col, InputNumber, Row, Slider, Select, Switch, Space, Button, message, Typography, Spin, Divider } from 'antd';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';

const { Text } = Typography

const AddOccupation = (props) => {
	const history = useHistory();
	const [loader, setLoader] = useState(false);
	const [countryList, setCountryList] = useState([]);
	const [occupationId, setOccupationId] = useState(null);
	const [form] = Form.useForm();
	const onCancel = () => {
		form.resetFields();
		history.push('/occupation');
	};
	useEffect(() => {
		if (props.location?.state?.occupationId) {
			setOccupationId(props.location.state?.occupationId)
			fetchOccupation(props.location.state?.occupationId)
		}

	}, [props]);

	useEffect(() => {
		countryFetch();
	}, []);

	const countryFetch = async () => {
		const response = await APIUtils.apiCall('GET', WebLinks.CountryList);
		if (response.resultObj?.statusCode === "2000") {

			setCountryList(response.resultObj.data);
		}
	};

	const fetchOccupation = async (id) => {
		setLoader(true)
		const response = await APIUtils.apiCall('GET', WebLinks.GetOccupation + id);
		if (response.resultObj?.statusCode === "2000") {
			form.setFieldsValue(response.resultObj.data)
		}
		setLoader(false)
	}

	const onChange = (newValue) => {
		form.setFieldsValue({
			cpaPercentage: newValue
		})
		//setInputValue(newValue);
	};

	const onFinish = async (values) => {

		let type = 'POST'
		if (occupationId) {
			type = 'PUT'
			values.occupationId = occupationId
		}
		const url = WebLinks.Occupation
		const response = await APIUtils.apiCall(type, url, JSON.stringify(values));
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			message.success(response.resultObj.message)
			history.push({
				pathname: '/occupation'
			});
		} else {
			message.error(response.resultObj.message)
		}
	};

	return (
		<Spin spinning={loader}>
			{occupationId &&
				<>
					<div className='uppercase font-bold text-xl'>Edit Supplier</div>
					<Divider className='my-3' />
				</>}
			<Form
				form={form}
				name="form_in_modal"
				layout="vertical"
				onFinish={onFinish}
				initialValues={{
					hourly: false,
					priceTier: false
				}}
			>
				<div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-4">
					<Form.Item
						name="occupation"
						label="Occupation"
						rules={[
							{
								required: true,
								message: 'Required field',
							},
						]}
						className="mb-0"
					>
						<Input />
					</Form.Item>

					<Form.Item
						name="keywords"
						label="Keywords"
						rules={[
							{
								required: true,
								message: 'Required field',
							},
						]}
						className="mb-0"
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="countryCode"
						label="Country Code"
						className="mb-0"
					>
						<Select
							showSearch
							placeholder="Select Country"
							className='w-full'
						>
							{countryList.map((country) => (
								<Select.Option key={country.countryCode} value={country.countryCode}>
									{country.county}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						name="language"
						label="Language"
						className="mb-0"
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="inEnglish"
						label="In English"
						className="mb-0"
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="sectorId"
						label="Sector Id"
						className="mb-0"
					>
						<InputNumber />
					</Form.Item>
					<Form.Item
						name="courseSubcategoryId"
						label="Course Subcategory Id"
						className="mb-0"
					>
						<InputNumber />
					</Form.Item>


					<div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-4">
						<Form.Item
							name="hourly"
							label="Hourly"
							valuePropName="checked"
						>
							<Switch checkedChildren="Yes" unCheckedChildren="No" />
						</Form.Item>
						<Form.Item
							name="priceTier"
							label="Price Tier"
							valuePropName="checked"
						>
							<Switch checkedChildren="Yes" unCheckedChildren="No" />
						</Form.Item>
					</div>
				</div>
				<div className='grid place-content-end'>
					<Space>
						<Form.Item>
							<Button className='uppercase' htmlType="button" onClick={onCancel}>
								Cancel
							</Button>
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Save
							</Button>
						</Form.Item>
					</Space>
				</div>
			</Form>
		</Spin>
	);
};

export default AddOccupation;