import { useState, useEffect } from 'react';
import { Form, Table, Checkbox, Space, Input, Button, Divider, InputNumber, message } from 'antd';
import { CountryDropdown } from 'react-country-region-selector';
import constants from '../../constants/constants';
import { Link } from "react-router-dom";
import WebLinks from '../../api/WebLinks';
import APIUtils from '../../api/APIUtils';

const { Search } = Input;

const ParentBuyerAccounts = (props) => {

	const [form] = Form.useForm();
	const [buyersList, setBuyersList] = useState([]);
	const [filteredBuyersList, setFilteredBuyersList] = useState([]);
	const [loader, setLoader] = useState(false);
	const [allowedBuyers, setAllowedBuyers] = useState([]);
	const [selectedBuyer, setSelectedBuyer] = useState([]);

	const SelectBuyersColumns = [
		{
			title: '',
			key: 'buyerId',
			width: '5%',
			render: (_, record) => (
				<Space>
					<Checkbox checked={record.parentBuyer} onChange={(e) => changeParentBuyer(e, record)} />
				</Space>
			),
		},
		{
			title: 'ID',
			dataIndex: 'buyerId',
			rowScope: 'row',
			width: '10%',
			sorter: (a, b) => a.buyerId - b.buyerId,
		},
		{
			title: 'Name',
			dataIndex: 'name',
			sorter: (a, b) => a.name - b.name,
		},
		{
			title: 'Parser Link',
			dataIndex: 'parserLink',
		},
	];

	useEffect(() => {
		setSelectedBuyer(props.selectedBuyer[0])
		fetchParentBuyers(props.selectedBuyer[0])
	}, [props]);

	const fetchBuyersList = async (parentBuyers) => {
		setLoader(true);
		const url = `${WebLinks.GetBuyersList}`;
		const response = await APIUtils.apiCall('GET', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			// const buyerIdSet = new Set(parentBuyers.map((item) => item.buyerId));
			// const dataWithKey = response.resultObj.data.map(item => ({ ...item, key: item.buyerId, subBuyer: buyerIdSet.has(item.subBuyerId) }));
			const buyersWithParentBuyers = response.resultObj.data.map(buyer => {
				return {
					...buyer,
					parentBuyer: parentBuyers.includes(buyer.buyerId),
				};
			});
			buyersWithParentBuyers.sort((a, b) => b.parentBuyer - a.parentBuyer);
			setBuyersList(buyersWithParentBuyers);
			//setBuyerListTotal(response.resultObj.getCount)
			setLoader(false);
		} else {
			setBuyersList([]);
			setLoader(false);
			message.error("Failed to fetch buyers");
		}
	};

	const fetchParentBuyers = async (selectedBuyer) => {
		// setLoader(true);
		const url = `${WebLinks.GetParentBuyers}${selectedBuyer}`;
		const response = await APIUtils.apiCall('GET', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			const selectedParentBuyers = response.resultObj.data.map(item => item.buyerId);
			setAllowedBuyers(selectedParentBuyers);
			fetchBuyersList(selectedParentBuyers)
			// setLoader(false);
		} else {
			setAllowedBuyers([]);
			// setLoader(false);
			message.error("Failed to fetch buyers");
		}
	};

	const changeParentBuyer = (e, record) => {
		const updatedAllowedBuyers = [...allowedBuyers];
		if (e.target.checked) {
			if (!updatedAllowedBuyers.includes(record.buyerId)) {
				updatedAllowedBuyers.push(record.buyerId);
			}
		} else {
			const index = updatedAllowedBuyers.indexOf(record.buyerId);
			if (index !== -1) {
				updatedAllowedBuyers.splice(index, 1);
			}
		}
		setAllowedBuyers(updatedAllowedBuyers);
		if (filteredBuyersList.length > 0) {
			const updatedfilteredBuyersList = filteredBuyersList.map(item => {
				if (item.buyerId === record.buyerId) {
					return { ...item, parentBuyer: e.target.checked };
				}
				return item;
			});
			setFilteredBuyersList(updatedfilteredBuyersList)
		}
		const updatedData = buyersList.map(item => {
			if (item.buyerId === record.buyerId) {
				return { ...item, parentBuyer: e.target.checked };
			}
			return item;
		});
		setBuyersList(updatedData);
	}

	const onSearchSubAccounts = (searchTerm) => {
		if (searchTerm) {
			const searchTermLower = searchTerm.toLowerCase();

			const results = buyersList.filter((item) => {
				return (
					item.buyerId.toString().includes(searchTermLower) ||
					item.name.toLowerCase().includes(searchTermLower) ||
					item.parserLink.toLowerCase().includes(searchTermLower)
				);
			});
			setFilteredBuyersList(results)
		}
		else {
			setFilteredBuyersList([])
		}
	}

	const saveParentBuyers = async () => {
		setLoader(true);
		//const buyerIdsParam = allowedBuyers.join(',');
		let payload;
		payload={
			buyerId:selectedBuyer,
			subBuyerId:allowedBuyers
		}
		const url = `${WebLinks.SaveParentBuyers}`;
		const response = await APIUtils.apiCall('POST', url,JSON.stringify(payload));
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			message.success("New Allowed Buyers Updated");
			setLoader(false);
		} else {
			setLoader(false);
			message.error("Failed to fetch buyers");
		}
	}

	return (
		<div>
			<div className='absolute top-2.5 right-6'>
				<Space>
					<Search
						placeholder="Search"
						allowClear
						onSearch={onSearchSubAccounts}
						enterButton
						className='w-60'
					/>
				</Space>
			</div>
			<span>{"("+allowedBuyers.length+" selected)"}</span>
			<Table
				loading={loader}
				columns={SelectBuyersColumns}
				dataSource={filteredBuyersList.length > 0 ? filteredBuyersList : buyersList}
				scroll={{
					y: 600
				}}
				pagination={false}
			/>
			<div className='absolute right-6 bottom-5'>
				<Button onClick={()=>{setFilteredBuyersList([]);setBuyersList([]);props.closeModal()}}>Cancel</Button>
				<Button type='primary' className='!capitalize' onClick={saveParentBuyers}>Save Parent Buyers</Button>
			</div>
		</div>
	);
};

export default ParentBuyerAccounts;