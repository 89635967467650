import { Tabs, Divider } from 'antd';
import constants from '../../constants/constants';
import { PlusOutlined, EyeOutlined } from '@ant-design/icons';
import AddImages from './AddImages';
import ViewImages from './ViewImages';

const { TabPane } = Tabs;

const Images = () => {

	return (
		<div>
      <div className='uppercase font-bold text-xl'>Images</div>
			<Divider className='my-3'/>
			<Tabs defaultActiveKey="1">
			<TabPane
        tab={ <span className='uppercase'><PlusOutlined className='text-xl' />{constants.AddNew}</span> }
        key={1}
      >
        <AddImages/>
      </TabPane>
			<TabPane
        tab={ <span className='uppercase'><EyeOutlined className='text-xl' />{constants.View}</span> }
        key={2}
      >
        <ViewImages/>
      </TabPane>
			</Tabs>
		</div>
	);
};

export default Images;