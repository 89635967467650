const constants = {
  Email: "Email",
  Password: "Password",
  Login: "Login",
  LoginEmailValid: "Enter Email",
  LoginPasswordValid: "Enter Password",
  CreateNew: "Create New",
  UploadHistoryList: ["Last ", <strong key='upload-history'>30 day</strong>, " upload history"],
  JobsIndexed: "jobs indexed on Hire Intelligence",
  PriorityLevels: "Priority Levels:",
  UpdatePriorityLevels: "Update all selected jobs to Priority Level:",
  AddNew: "Add New",
  View: "View",
  Pause: "Pause",
  Paused: "Paused",
  UpdateBtn: "Update",
  SelectBuyers: "Select Buyers",
  ManagePermissions: "Manage Permissions",
  Edit: "Edit",
  Companies:"Companies",
  Blocked:"Blocked List",
  MoreBtn:"Show more...",

  //Cookies
  COOKIE_EXPIRY_DAYS:3,
  COOKIE_TOKEN:'token',
  COOKIE_LOGGED_IN_USER_ID:'loggedInBuyerId',
  COOKIE_SELECTED_COUNTRY: 'selectedCountry',
  COOKIE_BUYER_SEARCH: 'buyerSearch',

  // Messages
  ERROR_MESSAGE: "Something went wrong. Please try again later.",
  NO_DATA: "No data found.",
  SUCCESS_SAVE: "Data saved successfully.",
  FAILED_SAVE: "Failed to save data.",
}
export default constants;