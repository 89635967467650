import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuth } from '../services/AuthService';
/**
 *
 * @param {Object} Route parameters
 * @description Route parameters must contain layout, component and other props. Layout has to be the master oage.
 */
export function RouteWithLayout({ layout, auth, component, ...rest }) {
    const { isUserAuthed } = useAuth();
    if (!isUserAuthed()) {
        return <Redirect to="/login" />
    }
    return (
        <Route
            {...rest}
            render={(props) =>
                React.createElement(
                    layout,
                    {
                        ...props,
                        ...rest
                    },
                    React.createElement(component, {
                        ...props,
                        ...rest
                    })
                )
            }
        />
    );
}
