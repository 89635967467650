import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Input, Space, Button, Popconfirm, Modal, message, Tooltip, Checkbox } from 'antd';
import { EditOutlined, DeleteOutlined, ClusterOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import WebLinks from '../../api/WebLinks';
import APIUtils from '../../api/APIUtils';
import constants from '../../constants/constants';
import AddSupplier from './AddSupplier'

const { Search } = Input;

const ViewSupplier = () => {
	const history = useHistory();
	const [loader, setLoader] = useState(false);
	const [suppliers, setSuppliers] = useState([]);
	const [filteredSuppliers, setFilteredSuppliers] = useState([]);
	const [total, setTotal] = useState();
	const [supplierId, setSupplierId] = useState(null);
	const [selectedSupplier, setSelectedSupplier] = useState();
	const [buyerIds, setBuyerIds] = useState();
	const [allowedBuyers, setAllowedBuyers] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [filteredBuyers, setFilteredBuyers] = useState([]);
	const [tableConfig, setTableConfig] = useState({
		pageNo: 1,
		limit: 50,
		sortBy: 'id',
		sortDesc: false,
		search: ''
	});

	const [isManagePermissionsModalOpen, setIsManagePermissionsModalOpen] = useState(false);
	const showManagePermissionsModal = () => {
		fetchAllowedBuyers()
		setIsManagePermissionsModalOpen(true);
	};
	const handleManagePermissionsOk = async () => {
		setLoader(true);
		const buyerIdsParam = buyerIds.join(',');

		const url = `${WebLinks.SaveAllowedBuyersSupplier}${selectedSupplier}&buyerIds=${encodeURIComponent(buyerIdsParam)}`;
		const response = await APIUtils.apiCall('POST', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			message.success("New Allowed Buyers Updated");
			setLoader(false);
		} else {
			setLoader(false);
			message.error("Failed to fetch buyers");
		}
		setIsManagePermissionsModalOpen(false);
	};
	const handleManagePermissionsCancel = () => {
		setIsManagePermissionsModalOpen(false);
	};

	const fetchAllowedBuyers = async () => {
		setLoader(true);
		const url = `${WebLinks.GetAllowedBuyersSuppliers}${selectedSupplier}`;
		const response = await APIUtils.apiCall('GET', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			const allowedBuyerIds = response.resultObj.data
				.filter(item => item.isAllowed)
				.map(item => item.buyerId);
			setBuyerIds(allowedBuyerIds)
			const dataWithKey = response.resultObj.data.map(item => ({ ...item, key: item.buyerId }));
			setAllowedBuyers(dataWithKey);
			setFilteredBuyers(dataWithKey)
			setLoader(false);
		} else {
			setAllowedBuyers([]);
			setFilteredBuyers([])
			setLoader(false);
			message.error("Failed to fetch buyers");
		}
	};

	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const showEditModal = (record) => {
		setSupplierId(record.supplierId)
		history.push({
			pathname: '/edit-supplier',
			state: { supplierId: record.supplierId }
		});
		//setIsEditModalOpen(true);
	};
	const handleEditCancel = () => {
		fetchSuppliers()
		setIsEditModalOpen(false);
	};


	const columns = [
		{
			title: 'ID',
			dataIndex: 'supplierId',
			width: '10%',
			sorter: true,
		},
		{
			title: 'Name',
			dataIndex: 'supplierName',
			sorter: true,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			sorter: true,
		},
		{
			title: 'Password',
			dataIndex: 'password',
		},
		{
			title: 'GUID',
			dataIndex: 'guid',
		},
		{
			title: 'Percentage',
			dataIndex: 'cpaPercentage',
		},
		{
			title: 'Generate XML',
			dataIndex: 'generateXML',
			align: 'center',
			render: (value) => <>
				{value ? <CheckOutlined className='text-xl text-lime-500' /> : <CloseOutlined className='text-xl text-red-500' />}
			</>,
		},
		{
			title: 'Actions',
			key: 'action',
			align: 'center',
			fixed: 'right',
			width: '10%',
			render: (_, record) => (
				<Space>
					<Tooltip title="Edit">
						<Button size='small' type="link" icon={<EditOutlined />} onClick={() => showEditModal(record)} />
					</Tooltip>
					<Popconfirm title="Sure to delete?" onConfirm={() => deleteSupplier(record)}>
						<Tooltip title="Delete" placement="bottom">
							<Button size='small' type="link" danger icon={<DeleteOutlined />} />
						</Tooltip>
					</Popconfirm>
				</Space>
			),
		}
	];

	// Select Buyers Table
	const SelectBuyersColumns = [
		{
			title: '',
			key: 'buyerId',
			width: '5%',
			render: (_, record) => (
				<Space>
					<Checkbox checked={record.isAllowed} onChange={(e) => changeSubBuyer(e, record)} />
				</Space>
			),
		},
		{
			title: 'ID',
			dataIndex: 'buyerId',
			rowScope: 'row',
			width: '10%'
		},
		{
			title: 'Name',
			dataIndex: 'name',
			sorter: (a, b) => a.name - b.name,
		},
		{
			title: 'Domain',
			dataIndex: 'domain',
		},
	];

	useEffect(() => {
		fetchSuppliers()
	}, [tableConfig]);

	const fetchSuppliers = async () => {
		setLoader(true);
		const queryParams = new URLSearchParams(tableConfig).toString();
		const url = `${WebLinks.Suppliers}?${queryParams}`;
		const response = await APIUtils.apiCall('GET', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			const dataWithKey = response.resultObj.data.map(item => ({ ...item, key: item.supplierId }));
			setSuppliers(dataWithKey);
			setFilteredSuppliers(dataWithKey)
			setTotal(response.resultObj.getCount)
			setLoader(false);
		} else {
			setSuppliers([]);
			setFilteredSuppliers()
			setLoader(false);
			message.error("Suppliers not found");
		}
	}

	const onSupplierSearch = async (value) => {
		// setSearchText(value);
		// const filteredData = suppliers.filter((supplier) =>
		// 	Object.values(supplier).some((columnValue) =>
		// 		columnValue.toString().toLowerCase().includes(value.toLowerCase())
		// 	)
		// );
		// console.log("filteredData", filteredData)
		// setFilteredSuppliers(filteredData);
		setTableConfig((prevConfig) => ({
			...prevConfig,
			pageNo: 1,
			search: value,
		}));
	}

	const onSearch = async (value) => {
		setSearchText(value);
		const filteredData = allowedBuyers.filter((buyer) =>
			buyer.name.toLowerCase().includes(value.toLowerCase())
		);
		setFilteredBuyers(filteredData);
	}

	const onChangeTable = (pagination, filters, sorter) => {
		const { current, pageSize } = pagination;
		setTableConfig((prevConfig) => ({
			...prevConfig,
			pageNo: current,
			limit: pageSize,
		}));

		if (sorter && sorter.field) {
			setTableConfig((prevConfig) => ({
				...prevConfig,
				sortBy: sorter.field,
				sortDesc: sorter.order === 'descend',
			}));
		}
	};

	const deleteSupplier = async (record) => {
		setLoader(true);
		const url = `${WebLinks.Suppliers}/${record.id}`;
		const response = await APIUtils.apiCall('PUT', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setLoader(false);
			message.error("Supplier deleted successfully");
		} else {
			setLoader(false);
			message.error("Could not delete supplier");
		}
	}

	// rowSelection object indicates the need for row selection
	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedSupplier(selectedRowKeys)
		}
	};

	const changeSubBuyer = (e, record) => {
		const updatedBuyers = allowedBuyers.map((buyer) =>
			buyer.buyerId === record.buyerId ? { ...buyer, isAllowed: e.target.checked } : buyer
		);
		setAllowedBuyers(updatedBuyers);

		if (filteredBuyers.length > 0) {
			const updatedFilteredBuyers = filteredBuyers.map((buyer) =>
				buyer.buyerId === record.buyerId ? { ...buyer, isAllowed: e.target.checked } : buyer
			);
			setFilteredBuyers(updatedFilteredBuyers);
		}
		const selectedIds = updatedBuyers
			.filter((buyer) => buyer.isAllowed)
			.map((buyer) => buyer.buyerId);

		setBuyerIds(selectedIds);
	}

	const buyerRowSelection = {
		selectedRowKeys: buyerIds,
		onChange: (selectedRowKeys, selectedRows) => {
			setBuyerIds(selectedRowKeys)
		}
	};

	return (
		<div>
			<div className='flex justify-between items-center mb-4'>
				<Space wrap>
					{/* <Button icon={<EditOutlined />} className='warning-btn uppercase' onClick={showEditModal}>{constants.Edit}</Button>
					<Popconfirm title="Sure to delete?">
						<Button className='bg-rose-600 text-white uppercase font-bold hover:!text-white hover:!border-rose-500' icon={<DeleteOutlined />}>
							Delete
						</Button>
					</Popconfirm> */}
					<Button icon={<ClusterOutlined />} type='primary' onClick={showManagePermissionsModal}>
						{constants.ManagePermissions}
					</Button>
				</Space>
				<Search
					placeholder="Search"
					allowClear
					onSearch={onSupplierSearch}
					enterButton
					className='w-60'
				/>
			</div>
			<div>
				<Table
					loading={loader}
					columns={columns}
					dataSource={filteredSuppliers}
					pagination={{
						pageSizeOptions: ["25", "50", "100"],
						total: total,
						current: tableConfig.pageNo,
						pageSize: tableConfig.limit,
					}}
					onChange={onChangeTable}
					scroll={{
						x: 1000
					}}
					rowSelection={{
						type: 'radio',
						...rowSelection,
					}}
				/>
			</div>
			<Modal
				title={
					<div className='flex justify-between items-center'>
						{constants.SelectBuyers}
						<Search
							placeholder="Search"
							allowClear
							onSearch={onSearch}
							enterButton
							className='w-60'
						/>
					</div>}
				closable={false}
				open={isManagePermissionsModalOpen}
				onOk={handleManagePermissionsOk}
				okText={<span className='capitalize'>Save Permissions</span>}
				onCancel={handleManagePermissionsCancel}
				className='top-5'
				width={1000}
			>
				<Table
					// rowSelection={{
					// 	type: 'checkbox',
					// 	...buyerRowSelection
					// }}
					columns={SelectBuyersColumns}
					dataSource={searchText ? filteredBuyers : allowedBuyers}
					scroll={{
						y: 300
					}}
					pagination={false}
				/>
			</Modal>
			{/* Edit Supplier Modal */}
			<Modal
				title={<span>{constants.Edit} Supplier</span>}
				open={isEditModalOpen}
				footer={null}
				onCancel={handleEditCancel}
				className='top-5'
				width={1000}
			>
				<AddSupplier supplierId={supplierId} />
			</Modal>
		</div>
	);
};

export default ViewSupplier;
