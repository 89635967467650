import React from "react";
import "../../index.css";
import "../../App.scss";
import { Layout } from "antd";
import Headers from "./Header";
import Sidemenu from "./Sider";
const { Content } = Layout;
const Layouts = (props) => {
	return (
		<div className='bg-img min-h-screen'>
			<Layout>
				<Headers />
				<Content className="container mx-auto">
					<Layout className="py-5">
						<Sidemenu />
						<Content className="mx-3 lg:ml-5 lg:mr-0 p-3 lg:p-5 bg-white min-h-screen border-2 border-gray-400 drop-shadow-xl">
							{React.cloneElement(props.children, { ...props, })}
						</Content>
					</Layout>
				</Content>
			</Layout>
		</div>
	);
};
export default Layouts;