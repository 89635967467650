import { useState, useEffect } from 'react';
import { Input, Table, message, DatePicker, Upload, Button, Form } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import WebLinks from '../api/WebLinks';
import APIUtils from '../api/APIUtils';
import Cookies from 'js-cookie';
import constants from '../constants/constants';
import { ExcelRenderer } from "react-excel-renderer";
import Papa from "papaparse";
const { Search } = Input;

const IndeedData = () => {
	const [form] = Form.useForm();
	const [loader, setLoader] = useState(false);
	const [images, setImages] = useState([]);
	const [total, setTotal] = useState();
	const [isUploading, setIsUploading] = useState(false);
	const [tableConfig, setTableConfig] = useState({
		pageNo: 1,
		limit: 50,
		search: ''
	});

	// Table start
	const ViewImagesColumns = [
		{
			title: 'ID',
			dataIndex: 'id',
			rowScope: 'row',
		},
		{
			title: 'CreatedOn',
			dataIndex: 'createdOn',
			key: 'createdOn',
		},
		{
			title: 'Month',
			dataIndex: 'month',
			key: 'month',
		},
		{
			title: 'Year',
			dataIndex: 'year',
			key: 'year',
		},
		{
			title: 'FileName',
			dataIndex: 'fileName',
			key: 'fileName',
		}
	];
	// Table end

	useEffect(() => {
		fetchIndeedData()
	}, [tableConfig]);

	const fetchIndeedData = async () => {
		setLoader(true);
		const queryParams = new URLSearchParams(tableConfig).toString();
		const url = `${WebLinks.IndeedData}?${queryParams}`;
		const response = await APIUtils.apiCall('GET', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setImages(response.resultObj.data);
			setTotal(response.resultObj.getCount)
			setLoader(false);
		} else {
			setImages([]);
			setLoader(false);
			message.error("Indeed Data not found");
		}
	}

	const onSearch = async (value) => {
		setTableConfig((prevConfig) => ({
			...prevConfig,
			search: value
		}));
	}

	const onChangeTable = (pagination) => {

		setTableConfig((prevConfig) => ({
			...prevConfig,
			pageNo: pagination
		}));

	};

	const onFinish = async (values) => {
		setLoader(true)
		const selectedDate = values.selectedDate;
		const year = selectedDate.format('YYYY');
		const month = selectedDate.format('MM');

		try {
			const formData = new FormData();
			formData.append('newImage', values.file.file);
			const token = 'Bearer ' + Cookies.get(constants.COOKIE_TOKEN);
			const url = `${WebLinks.IndeedDataSave}?year=${year}&month=${month}`;
			const response = await fetch(url, {
				method: 'POST',
				body: formData,
				headers: {
					Authorization: token,
				},
			});
			if (response.status === 200) {
				const data = await response.json();
				if (data.statusCode === "2000") {
					const indeedDataSpend = await readExcelFileData(values.file.file, data.data.id, year, month)
				} else {
					message.error(data.message);
				}
			} else {
				setLoader(false)
				message.error('An error occurred while uploading the file.');
			}
		} catch (error) {
			setLoader(false)
			console.error('Error uploading file:', error);
			message.error('An error occurred while uploading the file.');
		}
	}

	const readExcelFileData = (file, id, year, month) => {
		const handleData = (data) => {
			// Assuming the data structure is similar to your Excel structure
			const rawData = data.slice(1); // Skip the header row
			let indeedData = [];

			rawData.forEach((item, index) => {

				if (item.length) {
					if (item[16].includes("www.applygateway.com")) {
						const regex = /JobId=(\d+)/;
						const match = item[16].match(regex);

						if (match) {
							const jobId = match[1];
							let obj = {
								"IndeedDataId": id,
								"Month": month,
								"Year": year,
								"JobId": jobId,
								"JobTitle": item[0],
								"City": item[3] || "",
								"RefNumber": item[19].toString(),
								"CompanyName": item[21] || "",
								"SourceName": item[17],
								"Impressions": item[4],
								"Clicks": item[5],
								"ApplyStarts": item[6],
								"Applies": item[7],
								"TotalCost": item[15],
								"CPC": item[8],
								"CPAS": item[9],
								"CPA": item[10],
								"Country": item[1] || "",
								"jobUrl": item[16],
							};
							indeedData.push(obj);
						}
					}
				}
			});

			// Continue with the code to send the data to the server
			insertIndeedData(indeedData);
		};


		const handleExcel = (err, resp) => {
			if (err) {
				message.error('Error reading the Excel file.');
			} else {
				handleData(resp.rows);
			}
		};

		const handleCSV = (result) => {

			let indeedData = []
			result.data.map((item) => {
				if (item.jobURL?.includes("www.applygateway.com")) {
					const regex = /JobId=(\d+)/;
					const match = item.jobURL.match(regex);

					if (match) {
						const jobId = match[1];
						let obj = {
							"IndeedDataId": id,
							"Month": month,
							"Year": year,
							"JobId": jobId,
							"JobTitle": item.title,
							"City": item.city || "",
							"RefNumber": item.jobReferenceNumber.toString(),
							"CompanyName": item.jobCompanyName || "",
							"SourceName": item.sourceWebsite,
							"Impressions": item.sumImpressions,
							"Clicks": item.sumClicks,
							"ApplyStarts": item.sumApplyStarts,
							"Applies": item.sumApplies,
							"TotalCost": item.sumCostLocal,
							"CPC": item.avgCostPerClickLocal,
							"CPAS": item.avgCostPerApplyStartLocal,
							"CPA": item.avgCostPerApplyLocal,
							"Country": item.countryFullName || "",
							"jobUrl": item.jobURL,
						};
						indeedData.push(obj)
					}
				}
			})
			insertIndeedData(indeedData);
		};

		const reader = new FileReader();

		if (file.name.endsWith(".csv")) {
			reader.onload = function (e) {
				const text = e.target.result;
				Papa.parse(text, {
					complete: handleCSV,
					header: true, // Assuming the first row is the header
				});
			};
			reader.readAsText(file);
		} else if (file.name.endsWith(".xls") || file.name.endsWith(".xlsx")) {
			ExcelRenderer(file, handleExcel);
		} else {
			message.error("Unsupported file format.");
		}
	};



	const insertIndeedData = async (indeedDataSpend) => {

		const response = await APIUtils.apiCall("POST", WebLinks.IndeedDataSpend, JSON.stringify(indeedDataSpend));
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setLoader(false)
			onReset()
			fetchIndeedData()
			message.success(response.resultObj.message)
		} else {
			setLoader(false)
			message.error(response.resultObj.message)
		}
	}

	const onReset = () => {
		form.resetFields();
	};

	const acceptedFileTypes = ['application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'text/csv'];

	const props = {
		name: 'file',
		multiple: false,
		action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
		beforeUpload: (file) => {
			const isExcelFile = acceptedFileTypes.includes(file.type);
			if (!isExcelFile) {
				message.error('You can only upload Excel or CSV files!');
				// Clear the uploaded file and prevent the upload
				form.setFieldsValue({ file: null });
			}
			return false; // Prevent upload only if it's not an accepted file type
		},
		onChange(info) {
			const { status } = info.file;
			if (status !== 'uploading') {
			}
			if (status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onDrop(e) {
		},
	};

	const dummyRequest = ({ file, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};


	return (
		<div>
			<div className='flex justify-between items-center mb-3'>
				<Form form={form} name="myForm" onFinish={onFinish} layout='inline'>
					<Form.Item name="selectedDate"
						rules={[{ required: true, message: 'Please select Montha and Year' }]}>
						<DatePicker picker="month" />
					</Form.Item>

					<Form.Item name="file"
						rules={[{ required: true, message: 'Please select a file!' }]}
						shouldUpdate={(prevValues, currentValues) =>
							prevValues.file !== currentValues.file
						}
					>
						<Upload {...props} customRequest={dummyRequest} maxCount={1}>
							<Button icon={<UploadOutlined />}>
								Select File
							</Button>
						</Upload>
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" disabled={isUploading}>
							Upload
						</Button>
					</Form.Item>
				</Form>
				<Search
					placeholder="Search"
					allowClear
					onSearch={onSearch}
					enterButton
					className='w-60'
				/>
			</div>
			<div className='overflow-x-auto'>
				<Table
					loading={loader}
					columns={ViewImagesColumns}
					dataSource={images}
					pagination={{
						pageSizeOptions: ["25", "50", "100"],
						total: total,
						current: tableConfig.pageNo,
						pageSize: tableConfig.limit,
						onChange: onChangeTable
					}}
				/>
			</div>
		</div>
	);
};

export default IndeedData;