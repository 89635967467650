import { Table, Input, Space, Button, Tooltip } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

const { Search } = Input;

const BlockedCompanies = () => {


	const columns = [
		{
			title: 'Company Name',
			dataIndex: 'companyName',
      width:"80%",
			sorter: true,
		},
		{
			title: 'Actions',
			key: 'action',
			align: 'center',
			fixed: 'right',
			render: (_, record) => (
				<Space>
          <Tooltip title="Unblock this company" placement="left">
            <Button size='small' type="link" icon={<MinusCircleOutlined />} />
          </Tooltip>
				</Space>
			),
		}
	];
	const data = [
		{
			key: '1',
			companyName: 'Moonshine',
		},
		{
			key: '2',
			companyName: 'Technorix',
		}
	];

	return (
		<div>
			<div className='flex justify-end items-center mb-4'>
				<Search
					placeholder="Search"
					allowClear
					enterButton
					className='w-60'
				/>
			</div>
			<div className='overflow-x-auto'>
				<Table
					columns={columns}
					dataSource={data}
				/>
			</div>
		</div>
	);
};

export default BlockedCompanies;