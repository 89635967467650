import { Tabs, Divider } from 'antd';
import constants from '../../constants/constants';
import { PlusOutlined, EyeOutlined } from '@ant-design/icons';
import AddOccupation from './AddOccupation';
import ViewOccupation from './ViewOccupation';

const { TabPane } = Tabs;

const Occupation = () => {

  return (
    <div>
      <div className='uppercase font-bold text-xl'>Occupation</div>
      <Divider className='my-3' />
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={<span className='uppercase'><PlusOutlined className='text-xl' />{constants.View}</span>}
          key={1}
        >
          <ViewOccupation />
        </TabPane>
        <TabPane
          tab={<span className='uppercase'><EyeOutlined className='text-xl' />{constants.AddNew}</span>}
          key={2}
        >
          <AddOccupation />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Occupation;