import { useEffect, useState } from 'react';
import { Table, Input, Space, Tooltip, Button, Popconfirm, Modal, Form, Switch, message, Typography } from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import WebLinks from '../api/WebLinks';
import APIUtils from '../api/APIUtils';

const { Search } = Input;

const { Text } = Typography

const Users = () => {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState();
  const [tableConfig, setTableConfig] = useState({
    pageNo: 1,
    limit: 50,
    sortBy: 'id',
    sortDesc: false,
    search: ''
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const [form] = Form.useForm();


  const UsersColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      sorter: (a, b) => a.occupation - b.occupation,
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Daily Emails',
      dataIndex: 'dailyEmails',
      key: 'dailyEmails',
      align: 'center',
      render: (record) => <>
        {record ? <CheckOutlined className='text-xl text-lime-500' /> :
          <CloseOutlined className='text-xl text-gray-400' />
        }

      </>,
    },
    {
      title: 'High Match Emails',
      dataIndex: 'highMatchEmails',
      key: 'highMatchEmails',
      align: 'center',
      render: (record) => <>
        {record ? <CheckOutlined className='text-xl text-lime-500' /> :
          <CloseOutlined className='text-xl text-gray-400' />
        }
      </>,
    },
    {
      title: 'SMS Block',
      dataIndex: 'smsBlock',
      key: 'smsBlock',
      align: 'center',
      render: (record) => <>
        {record ? <CheckOutlined className='text-xl text-lime-500' /> :
          <CloseOutlined className='text-xl text-gray-400' />
        }
      </>,
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: '10%',
      render: (_, record) => (
        <Space>
          <Tooltip title="Edit">
            <Button size='small' type="link" icon={<EditOutlined />} onClick={() => showEditModal(record)} />
          </Tooltip>
          <Popconfirm title="Sure to delete?" onConfirm={() => deleteUser(record)}>
            <Tooltip title="Delete" placement="bottom">
              <Button size='small' type="link" danger icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    }
  ];

  useEffect(() => {
    fetchUsers()
  }, [tableConfig]);

  const fetchUsers = async () => {
    setLoader(true);
    const queryParams = new URLSearchParams(tableConfig).toString();
    const url = `${WebLinks.GetUsers}?${queryParams}`;
    const response = await APIUtils.apiCall('GET', url);
    if (!response.isCatch && response.resultObj.statusCode === "2000") {
      setUsers(response.resultObj.data);
      setTotal(response.resultObj.getCount)
      setLoader(false);
    } else {
      setUsers([]);
      setLoader(false);
      message.error("Users not found");
    }
  }

  const onSearch = async (value) => {
    setTableConfig((prevConfig) => ({
      ...prevConfig,
      search: value
    }));
  }

  const onChangeTable = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    setTableConfig((prevConfig) => ({
      ...prevConfig,
      pageNo: current,
      limit: pageSize,
    }));

    if (sorter && sorter.field) {
      setTableConfig((prevConfig) => ({
        ...prevConfig,
        sortBy: sorter.field,
        sortDesc: sorter.order === 'descend',
      }));
    }
  };

  const updateUser = async (values) => {
    setLoader(true);
    const url = WebLinks.UpdateUsers
    const payload = {
      "id": currentRecord.id,
      "fullName": values.fullName,
      "email": currentRecord.email,
      "phone": currentRecord.phone,
      "dailyEmails": true,
      "highMatchEmails": values.highMatchEmails,
      "smsBlock": values.smsBlock
    }
    const response = await APIUtils.apiCall('PUT', url, JSON.stringify(payload));
    if (!response.isCatch && response.resultObj.statusCode === "2000") {
      form.resetFields()
      setCurrentRecord(null)
      setIsEditModalOpen(false);
      fetchUsers()
      setLoader(false);

      message.success(response.resultObj.message)
    } else {
      setLoader(false);
      message.error("Users not found");
    }
  }

  const deleteUser = async (record) => {
    setLoader(true);
    const url = `${WebLinks.DeleteUsers}${record.id}`;
    const response = await APIUtils.apiCall('PUT', url);
    if (!response.isCatch && response.resultObj.statusCode === "2000") {
      fetchUsers()
      setLoader(false);
      message.error("User deleted successfully");
    } else {
      setLoader(false);
      message.error("Could not delete user");
    }
  }

  const showEditModal = (record) => {
    form.setFieldsValue(record)
    setCurrentRecord(record)
    setIsEditModalOpen(true);
  };
  const handleCancel = () => {
    form.resetFields()
    setCurrentRecord(null)
    setIsEditModalOpen(false);
  };

  const handleDeleteUser = async (record) => {
    setLoader(true);
    const url = `${WebLinks.DeleteUsers}${record.id}`;
    const response = await APIUtils.apiCall('PUT', url);
    if (!response.isCatch && response.resultObj.statusCode === "2000") {
      message.success("Deleted user")
      fetchUsers()
      setLoader(false);
    } else {
      setLoader(false);
      message.error("Could not delete user");
    }
  };
  return (
    <div>
      <div className='flex justify-between items-center'>
        <div className='uppercase font-bold text-xl'>Users</div>
        <Search
          placeholder="Search"
          allowClear
          onSearch={onSearch}
          enterButton
          className='w-60'
        />
      </div>
      <div className='mt-3'>
        <Table
          loading={loader}
          columns={UsersColumns}
          dataSource={users}
          pagination={{
            pageSizeOptions: ["25", "50", "100"],
            total: total,
            current: tableConfig.pageNo,
            pageSize: tableConfig.limit,
          }}
          onChange={onChangeTable}
          scroll={{
						x: 1000
					}}
        />
      </div>
      <Modal
        title="Edit Job details"
        destroyOnClose={true}
        open={isEditModalOpen} onCancel={handleCancel}
        onOk={() => form.submit()}
        okText={<span className='capitalize'>Save</span>}
        style={{ top: 20 }}
      >
        <Form
          form={form}
          name="form_in_modal"
          layout="vertical"
          onFinish={updateUser}
        >
          <Form.Item
            name="fullName"
            label="Full Name"
          >
            <Text>{currentRecord?.fullName}</Text>
          </Form.Item>
          <div className="grid xs:grid-cols-1 lg:grid-cols-3 gap-4">
            <Form.Item
              name="dailyEmails"
              label="Daily Emails"
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={currentRecord?.dailyEmails} />
            </Form.Item>
            <Form.Item
              name="highMatchEmails"
              label="High Match Emails"
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={currentRecord?.highMatchEmails} />
            </Form.Item>
            <Form.Item
              name="smsBlock"
              label="SMS Block"
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={currentRecord?.checkedChildren} />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Users;
