import { InboxOutlined } from '@ant-design/icons';
import { Form, Input, message, Upload, Button, Space } from 'antd';
import WebLinks from '../../api/WebLinks';
import APIUtils from '../../api/APIUtils';
import Cookies from 'js-cookie';
import constants from '../../constants/constants';

const { Dragger } = Upload;

const AddImages = () => {
  const [form] = Form.useForm();

  const props = {
    name: 'file',
    // multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        //message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const onReset = () => {
    form.resetFields();
  };

  const uploadImage = async (values) => {
    try {
      const formData = new FormData();
      formData.append('newImage', values.imgupload.file.originFileObj);
      //formData.append('imageName', values.name);

      const token = 'Bearer ' + Cookies.get(constants.COOKIE_TOKEN);

      const response = await fetch('https://partnersapi.applygateway.com/api/BackofficeImage/AddImage?imageName=' + values.name, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: token, // Use the 'token' variable directly
        },
      });

      if (response.status === 200) { // Check the HTTP status code
        const data = await response.json(); // Parse the response as JSON

        if (data.statusCode === "2000") {
          onReset()
          message.success(data.message);
        } else {
          message.error(data.message);
        }
      } else {
        message.error('An error occurred while uploading the file.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      message.error('An error occurred while uploading the file.');
    }
  }


  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <div>
      <Form name="imgupload" layout='vertical'
        autoComplete="off"
        form={form}
        onFinish={uploadImage}
      >
        <Form.Item
          label="Image Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please enter image name!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="imgupload"
          rules={[
            {
              required: true,
              message: 'Please upload an image!',
            },
          ]}
        >
          <Dragger {...props}
            maxCount={1}
            customRequest={dummyRequest}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag image to this area to upload</p>
            <p className="ant-upload-hint">
              {/* Support for a single or bulk upload.  */}Strictly prohibited from uploading company data or other
              banned files.
            </p>
          </Dragger>
        </Form.Item>
        <Form.Item>
          <div className='float-right'>
            <Space>
              <Button className='uppercase' htmlType="button" onClick={onReset}>
                Reset
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddImages;