import { useEffect, useState } from 'react';
import { Table, Input, message} from 'antd';
import WebLinks from '../../api/WebLinks';
import APIUtils from '../../api/APIUtils';
import constants from '../../constants/constants';
import dayjs from 'dayjs';

const { Search } = Input;

const BoostTenants = () => {
  const [loader, setLoader] = useState(false);
  const [boostTenantsData, setBoostTenantsData] = useState([]);
  const [total, setTotal] = useState();
  const [tableConfig, setTableConfig] = useState({
    pageNo: 1,
    limit: 200,
    sortBy: 'id',
    sortDesc: false,
    search: ''
  });

  const BoostTenantsColumns = [
    {
      title: 'Buyer ID',
      dataIndex: 'buyerId',
      key: 'buyerId',
      width: '10%',
    },
    {
      title: 'Name',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: 'Stream Key',
      dataIndex: 'stream_Key',
      key: 'stream_Key',
    },
    {
      title: 'Created On',
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (text) => <>
      <span>{dayjs(text).format('YYYY-MM-DD')}</span>
    </>,
    },
  ];

  useEffect(() => {
    fetchBoostTenants()
  }, [tableConfig]);

  const fetchBoostTenants = async () => {
    setLoader(true);
    const queryParams = new URLSearchParams(tableConfig).toString();
    const url = `${WebLinks.GetBoostTenants}?${queryParams}`;
    const response = await APIUtils.apiCall('GET', url);
    if (!response.isCatch && response.resultObj.statusCode === "2000") {
      setBoostTenantsData(response?.resultObj?.data||[]);
      setTotal(response?.resultObj?.getCount||0)
      setLoader(false);
    } else {
      setBoostTenantsData([]);
      setTotal(0);
      setLoader(false);
      message.error(constants.NO_DATA);
    }
  }

  const onSearch = async (value) => {
    setTableConfig((prevConfig) => ({
      ...prevConfig,
      search: value,
      pageNo: 1,
    }));
  }

  const onChangeTable = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    setTableConfig((prevConfig) => ({
      ...prevConfig,
      pageNo: current,
      limit: pageSize,
    }));

    if (sorter && sorter.field) {
      setTableConfig((prevConfig) => ({
        ...prevConfig,
        sortBy: sorter.field,
        sortDesc: sorter.order === 'descend',
      }));
    }
  };

  return (
    <div>
      <div className='flex justify-between items-center'>
        <div className='uppercase font-bold text-xl'>Boost Tenants</div>
        <Search
          placeholder="Search"
          allowClear
          onSearch={onSearch}
          enterButton
          className='w-60'
        />
      </div>
      <div className='mt-3'>
        <Table
          loading={loader}
          columns={BoostTenantsColumns}
          dataSource={boostTenantsData}
          pagination={{
            pageSizeOptions: ["200", "500", "1000"],
            total: total,
            current: tableConfig.pageNo,
            pageSize: tableConfig.limit,
          }}
          onChange={onChangeTable}
          scroll={{
						x: 1000
					}}
        />
      </div>
    </div>
  );
};

export default BoostTenants;
