import { Table, Input, Space, Button, Popconfirm, Tooltip } from 'antd';
import { StopOutlined } from '@ant-design/icons';

const { Search } = Input;

const ViewCompanies = () => {


	const columns = [
		{
			title: 'Company Name',
			dataIndex: 'companyName',
      width:"80%",
			sorter: true,
		},
		{
			title: 'Actions',
			key: 'action',
			align: 'center',
			fixed: 'right',
			render: (_, record) => (
				<Space>
					<Popconfirm title="Sure to block?">
						<Tooltip title="Block this company" placement="left">
							<Button size='small' type="link" danger icon={<StopOutlined />} />
						</Tooltip>
					</Popconfirm>
				</Space>
			),
		}
	];
	const data = [
		{
			key: '1',
			companyName: 'OPSPL',
		},
		{
			key: '2',
			companyName: 'Creative Capsule',
		}
	];

	return (
		<div>
			<div className='flex justify-end items-center mb-4'>
				<Search
					placeholder="Search"
					allowClear
					enterButton
					className='w-60'
				/>
			</div>
			<div className='overflow-x-auto'>
				<Table
					columns={columns}
					dataSource={data}
				/>
			</div>
		</div>
	);
};

export default ViewCompanies;