import { Tabs, Divider } from 'antd';
import constants from '../../constants/constants';
import { UnorderedListOutlined, StopOutlined } from '@ant-design/icons';
import ViewCompanies from './ViewCompanies';
import BlockedCompanies from './BlockedCompanies';

const { TabPane } = Tabs;

const Companies = () => {

	return (
		<div>
      <div className='uppercase font-bold text-xl'>{constants.Companies}</div>
			<Divider className='my-3'/>
			<Tabs defaultActiveKey="1">
			<TabPane
        tab={ <span className='uppercase'><UnorderedListOutlined className='text-xl' />List of Companies</span> }
        key={1}
      >
        <ViewCompanies/>
      </TabPane>
			<TabPane
        tab={ <span className='uppercase'><StopOutlined className='text-xl' />{constants.Blocked}</span> }
        key={2}
      >
        <BlockedCompanies/>
      </TabPane>
			</Tabs>
		</div>
	);
};

export default Companies;